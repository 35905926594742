import React from "react";
import SEO from "components/seo";

const Internships = () => {
  return (
    <main style={{ backgroundColor: "white" }}>
      <SEO title="Internships | Thomas Careers" />
      <section className="page-utility">
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol
              className="breadcrumb"
              style={{ marginBlockStart: "0", marginBlockEnd: "0" }}
            >
              <li className="breadcrumb-item">
                <a href="https://business.thomasnet.com/about/">About</a>
              </li>
              <li className="breadcrumb-item">
                <span xmlns="http://rdf.data-vocabulary.org/#" />
                <span className="breadcrumb_last">Internships</span>
              </li>
            </ol>
          </nav>
        </div>
      </section>

      <header className="page-header">
        <div className="container">
          <h1 className="page-header__title font-weight-normal" style={{fontSize:"50px"}}>
            Thomas Internships
          </h1>
        </div>
      </header>

      <div className="container">
        <div className="internship-container">
          <div className="content-container">
            <p>
              Located in New York City, our Internship Program engages students
              in a wide range of projects through a combination of hands-on
              work, skill building, and client interaction. Working side-by-side
              with our energetic team of Mechanical Engineers, Designers,
              Industry and Web Technology experts, each intern will be exposed
              to unique, professional opportunities and real-life experience in
              their field.
            </p>
            <p>
              ThomasNet.com is the Internet&#8217;s leading vertical destination
              site for buyers of industrial products and services. Frequent
              visitors include Fortune 500 companies, the military, government
              entities, educational and research institutions, and buyers and
              engineers in companies of all sizes.
            </p>
          </div>
          <div className="iframe-container">
            <div className="resp-container">
              <iframe
                className="resp-iframe"
                src="https://www.youtube.com/embed/krIrm8ocNFw"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </div>
      <section className="dark-module slant-gray-right">
        <div
          className="container"
          style={{ backgroundColor: "#ecf1f5", maxWidth: "100%" }}
        >
          <h2 className="large-header">
            Do You <span> Qualify?</span>
          </h2>
          <p className="large-text ">
            You must be a student entering your junior or senior year of
            college. And, you must be able to work legally in the US, possess
            clear written and oral communication skills, and show an
            enthusiastic customer service attitude. This is a paid internship
            with the possibility to gain college credits.
          </p>
        </div>
      </section>
    </main>
  );
};

export default Internships;
